jQuery(document).ready(function ($) {
  if ($('.m-video__trigger')[0]) {
    $('.m-video__trigger').click(function () {
      console.log('clicked');
      const $wrapper = $(this).closest('.m-video__wrapper');
      const $iframe = $wrapper.find('.m-video__iframe');
      const $thumbnail = $wrapper.find('.m-video__thumbnail');
      const iframe_src = $iframe.data('src');

      $iframe.attr('src', iframe_src + '&autoplay=1');
      $thumbnail.fadeOut();
      console.log($iframe.attr('src'));
    });
  }
});
