jQuery(document).ready(function ($) {
  //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
  // ✅ Atom: Select
  //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

  function openSelectList(el) {
    const $select = $(el).closest('.a-select');
    const $list = $select.find('.a-select__list');
    const $option = $list.find(
      '.a-select__item--selected, .a-select__item:first-child'
    );

    $select.addClass('a-select--open');
    $list.show();
    $option.focus();
  }

  function closeSelectList(el) {
    const $select = $(el).closest('.a-select');
    const $input = $select.find('.a-select__input');
    const $list = $select.find('.a-select__list');

    $select.removeClass('a-select--open');
    $list.hide();
    $input.focus();
  }

  function toggleSelectList(el) {
    const $select = $(el).closest('.a-select');
    const $list = $select.find('.a-select__list');

    if ($list.is(':visible')) {
      closeSelectList(el);
    } else {
      openSelectList(el);
    }
  }

  function searchSelectList(el, query) {
    const $select = $(el).closest('.a-select');
    const $items = $select.find('.a-select__item');

    const queryText = query.trim().toLowerCase();

    $items
      .filter(function () {
        const itemText = $(this).text().trim().toLowerCase();
        return itemText.indexOf(queryText) === 0;
      })
      .first()
      .focus();
  }

  function chooseSelectOption(el) {
    const $select = $(el).closest('.a-select');
    const $nSelect = $select.find('.a-select__native');
    const $input = $select.find('.a-select__input');
    const $items = $select.find('.a-select__item');

    $items
      .removeClass('a-select__item--selected')
      .attr('aria-selected', 'false');

    $(el).addClass('a-select__item--selected').attr('aria-selected', 'true');

    const label = $input.data('label');
    const value = $(el).text();
    const position = $(el).index() + 1;
    const count = $input.data('count');

    const ariaLabel = `${label}, ${value} Selected, ${position} of ${count}`;

    $input.text($(el).text()).attr('aria-label', ariaLabel);
    $select.removeClass('a-select--unset');
    $nSelect.val($(el).data('value'));
    $nSelect.trigger('change');

    closeSelectList(el);
  }

  $.fn.select = function () {
    this.find('.a-select__input').mousedown(function (e) {
      e.preventDefault();
      toggleSelectList(this);
    });

    this.find('.a-select__input').keydown(function (e) {
      switch (e.key) {
        case 'Enter':
        case 'spacebar':
        case ' ':
        case 'ArrowDown':
        case 'ArrowUp':
          e.preventDefault();
          openSelectList(this);
          break;

        case 'Escape':
        case 'Esc':
        case 'Delete':
        case 'Backspace':
          e.preventDefault();

          if ($(this).data('placeholder')) {
            const $select = $(this).closest('.a-select');
            const placeholder = $(this).data('placeholder');
            const label = $(this).data('label');

            $select.addClass('a-select--unset');
            $select.find('.a-select__native').val('');
            $select
              .find('.a-select__item')
              .removeClass('a-select__item--selected')
              .attr('aria-selected', 'false');

            $(this).text(placeholder).attr('aria-label', label).blur().focus();
          }
          break;
      }
    });

    this.find('.a-select__item').mousedown(function (e) {
      e.preventDefault();
      chooseSelectOption(this);
    });

    this.find('.a-select__item').blur(function (e) {
      const newTarget = e.relatedTarget;

      if (
        newTarget === null ||
        !newTarget.classList.contains('a-select__item') ||
        e.target.offsetParent != newTarget.parentElement
      ) {
        closeSelectList(this);
      }
    });

    let searchTimeout = false;
    let searchQuery = '';

    this.find('.a-select__item').keydown(function (e) {
      e.preventDefault();

      switch (e.key) {
        case 'Enter':
        case 'spacebar':
        case ' ':
          chooseSelectOption(this);
          break;

        case 'Escape':
        case 'Esc':
        case 'Tab':
        case 'Delete':
        case 'Backspace':
          closeSelectList(this);
          break;

        case 'ArrowUp':
          var prevItem = e.target.previousElementSibling;
          if (prevItem) prevItem.focus();
          break;

        case 'ArrowDown':
          var nextItem = e.target.nextElementSibling;
          if (nextItem) nextItem.focus();
          break;

        case 'Home':
          $(this).parent().find('.a-select__item:first-child').focus();
          break;

        case 'End':
          $(this).parent().find('.a-select__item:last-child').focus();
          break;

        default:
          searchQuery += e.key;

          searchSelectList(this, searchQuery);

          if (searchTimeout) clearTimeout(searchTimeout);

          searchTimeout = setTimeout(function () {
            searchQuery = '';
          }, 1000);
      }
    });
  };

  if ($('.a-select')[0]) {
    $('.a-select').select();
  }
});
