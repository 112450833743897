jQuery(document).ready(function ($) {
  //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
  // ✅ Accordion
  //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

  function openAccordion(el) {
    const $accordion = $(el).closest('.m-accordion');
    const $toggle = $accordion.find('.m-accordion__toggle');
    const $panel = $accordion.find('.m-accordion__panel');
    const $group = $(el).closest('.m-accordions');

    $accordion.addClass('m-accordion--open');
    $toggle.attr('aria-expanded', 'true');
    $panel.slideDown(200);

    if (
      $toggle.attr('aria-disabled') &&
      $toggle.attr('aria-disabled') === 'false'
    ) {
      $toggle.attr('aria-disabled', 'true');

      if ($group[0]) {
        const $other_accordions = $group.find('.m-accordion__toggle').not(el);

        $other_accordions.attr('aria-disabled', 'false');
        closeAccordion($other_accordions);
      }
    }
  }

  function closeAccordion(el) {
    const $accordion = $(el).closest('.m-accordion');
    const $toggle = $accordion.find('.m-accordion__toggle');
    const $panel = $accordion.find('.m-accordion__panel');

    if (
      !$toggle.attr('aria-disabled') ||
      $toggle.attr('aria-disabled') === 'false'
    ) {
      $accordion.removeClass('m-accordion--open');
      $toggle.attr('aria-expanded', 'false');
      $panel.slideUp(200);
    }
  }

  function toggleAccordion(el) {
    const $accordion = $(el).closest('.m-accordion');
    const $panel = $accordion.find('.m-accordion__panel');

    if ($panel.is(':visible')) {
      closeAccordion(el);
    } else {
      openAccordion(el);
    }
  }

  if ($('.m-accordion')[0]) {
    $('.m-accordion__toggle').click(function () {
      toggleAccordion(this);
    });

    $('.m-accordion__toggle').keydown(function (e) {
      const $groupItem = $(this).closest('.m-accordions__item');

      switch (e.key) {
        case 'Enter':
        case 'spacebar':
        case ' ':
          e.preventDefault();
          toggleAccordion(this);
          break;

        case 'ArrowUp':
          e.preventDefault();
          if ($groupItem) {
            const $prevGroupItem = $groupItem.prev();

            if ($prevGroupItem[0]) {
              $prevGroupItem.find('.m-accordion__toggle').focus();
            }
          }
          break;

        case 'ArrowDown':
          e.preventDefault();
          if ($groupItem) {
            const $nextGroupItem = $groupItem.next();

            if ($nextGroupItem[0]) {
              $nextGroupItem.find('.m-accordion__toggle').focus();
            }
          }
          break;

        case 'Home':
          e.preventDefault();
          if ($groupItem) {
            $groupItem
              .parent()
              .find('.m-accordions__item')
              .first()
              .find('.m-accordion__toggle')
              .focus();
          }
          break;

        case 'End':
          e.preventDefault();
          if ($groupItem) {
            $groupItem
              .parent()
              .find('.m-accordions__item')
              .last()
              .find('.m-accordion__toggle')
              .focus();
          }
          break;
      }
    });
  }
});
