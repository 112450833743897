/* global AJAX */
jQuery(document).ready(function ($) {
  if ($('.m-compatible-parts-form')[0]) {
    $('select[name="equipment-select"]').change(function () {
      $.ajax({
        type: 'POST',
        url: AJAX.URL,
        data: {
          action: 'get_compatible_parts',
          product: $(this).val(),
        },
        dataType: 'json',
        beforeSend: function () {
          $('.m-compatible-parts-form').addClass(
            'm-compatible-parts-form--loading'
          );
        },
        success: function (response) {
          if (response.success) {
            $('select[name="parts-select"]')
              .parent()
              .html(response.data)
              .select();
            $('.m-compatible-parts-form__field').removeClass(
              'm-field--disabled'
            );
            $('.m-compatible-parts-form__submit').removeAttr('disabled');
          }
        },
        complete: function () {
          $('.m-compatible-parts-form').removeClass(
            'm-compatible-parts-form--loading'
          );
        },
      });
    });
  }
});
