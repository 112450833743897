jQuery(document).ready(function ($) {
  if ($('.m-modal')[0] && $('.m-product-slider')[0]) {
    $('.m-modal .m-product-slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      fade: true,
      asNavFor: '.o-single-product__slider-nav',
    });
  }
});
