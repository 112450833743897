jQuery(document).ready(function ($) {
  $('#main-menu').on('expand', function () {
    $(this).addClass('o-header__nav--open');
    $(this).find('.m-page-nav__item:first-child a').focus();

    const controlledBy = $(this).attr('data-controlledby');
    const $controller = $('#' + controlledBy);

    $controller.attr('aria-expanded', 'true');
    $('body').css('overflow', 'hidden');
  });

  $('#main-menu').on('unexpand', function () {
    $(this).removeClass('o-header__nav--open');

    const controlledBy = $(this).attr('data-controlledby');
    const $controller = $('#' + controlledBy);

    $controller.attr('aria-expanded', 'false');
    $('body').removeAttr('style');
  });

  $('.o-header__hamburger').click(function () {
    const ariaExpanded = $(this).attr('aria-expanded');
    const $headerItems = $('#main-menu');

    if (ariaExpanded === 'true') {
      $headerItems.trigger('unexpand');
    } else {
      $headerItems.trigger('expand');
    }
  });

  $('#main-menu').click(function (e) {
    const controlledBy = $(this).attr('data-controlledby');
    const $controller = $('#' + controlledBy);

    if ($(e.target).is('.m-page-nav__item')) return;
    if ($(e.target).is('.m-page-nav__item--meganav > .m-page-nav__link'))
      return;
    $controller.removeClass('a-hamburger--toggled');
    $(this).trigger('unexpand');
  });
});
