jQuery(document).ready(function ($) {
  if ($('.o-single-product')[0]) {
    $('.o-single-product__slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      asNavFor: '.o-single-product__slider-nav',
    });

    $('.o-single-product__slider-nav').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      asNavFor: '.m-product-slider',
      dots: false,
      arrows: true,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    });
  }
});
