jQuery(document).ready(function ($) {
  function openTabPanel(el) {
    const controlId = $(el).attr('aria-controls');
    const $panel = $(`#${controlId}`);
    const $panelList = $panel.closest('.m-tab-panels');

    $panel.show();

    if ($panelList) {
      $panelList.find('.m-tab-panel').not($panel).hide();
    }
  }

  if ($('.a-tab')[0]) {
    $('.a-tab').click(function () {
      openTabPanel(this);
    });

    $('.a-tab').keydown(function (e) {
      const $list = $(this).closest('.m-tab-list__list');
      const isAutomatic = $list[0] && $list.data('automatic');

      if (!isAutomatic) {
        if (e.key === 'Enter' || e.key === 'spacebar' || e.key === ' ') {
          openTabPanel(this);
        }
      }
    });

    $('.a-tab').focus(function () {
      const $list = $(this).closest('.m-tab-list__list');
      const isAutomatic = $list[0] && $list.data('automatic');

      if (isAutomatic) openTabPanel(this);
    });
  }
});
