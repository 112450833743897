jQuery(document).ready(function ($) {
  $(document).on('change', '#copy_from_billing', function () {
    var fields = [
      'first_name',
      'last_name',
      'company',
      'address_1',
      'address_2',
      'city',
      'state',
      'postcode',
      'country',
      'phone',
    ];

    if (!$('#copy_from_billing').is(':checked')) return;

    $.each(fields, function (index, name) {
      $("[name='shipping_" + name + "']")
        .val($("[name='billing_" + name + "']").val())
        .trigger('change');
    });
  });
});
