jQuery(document).ready(function ($) {
  if ($('.o-hero-carousel')[0]) {
    $('.o-hero-carousel__wrapper').on('init', function () {
      const $iframe = $(this).find('.m-video__iframe');
      const video_id = $iframe.data('video-id');
      const video_src =
        $iframe.data('src') +
        '&playlist=' +
        video_id +
        '&autoplay=1&mute=1&loop=1&controls=0';
      const $wrapper = $(this).find('.m-video__wrapper');

      $iframe.attr('src', video_src);
      $wrapper.addClass('m-video__wrapper--playing');
    });

    $('.o-hero-carousel__wrapper').slick({
      dots: true,
      arrows: true,
      // autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      // adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            dots: false,
          },
        },
      ],
    });
  }
});
