jQuery(document).ready(function ($) {
  if ($('.a-search-button')[0]) {
    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    // ✅ Click
    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

    $('.a-search-button').click(function () {
      const controlledId = $(this).attr('aria-controls');
      const expandedAttr = $(this).attr('aria-expanded');

      if (expandedAttr) {
        if (expandedAttr === 'true') {
          $(this).trigger('unexpand');
        } else {
          $(this).trigger('expand');
        }
      }

      if (controlledId) {
        const $controlled = $(`#${controlledId}`);

        if ($controlled.is(':visible')) {
          $controlled.trigger('unexpand');
        } else {
          $controlled.trigger('expand');
        }
      }
    });

    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    // ✅ Expand
    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

    $('.a-search-button').on('expand', function () {
      $(this).attr('aria-expanded', 'true');
    });

    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    // ✅ Unexpand
    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

    $('.a-search-button').on('unexpand', function () {
      $(this).attr('aria-expanded', 'false');
    });
  }
});
