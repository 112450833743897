jQuery(document).ready(function ($) {
  //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
  // ✅ Molecule: Modal
  //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

  function closeModal(el, trigger) {
    const $modal = $(el).closest('.m-modal');
    const triggerId = $modal.data('controlledby');

    if ($modal.hasClass('m-modal--required')) return;

    $modal.removeClass('m-modal--open').attr('tabindex', '-1');

    if (triggerId) {
      $(`#${triggerId}`)
        .removeClass('m-modal-trigger__trigger--active')
        .focus();
    }

    $modal.trigger('close', [trigger]);
  }

  function openModal(id, focusOnModal, trigger) {
    const $modal = $(`#${id}`);

    $modal.addClass('m-modal--open').attr('tabindex', '1');

    if (focusOnModal) {
      setTimeout(function () {
        $modal.focus();
      }, 100);
    } else {
      const $focusable = $modal.find(
        'button, [href], input:not([type="hidden"]), select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      const $firstFocusable = $focusable[0];

      setTimeout(function () {
        $firstFocusable.focus();
      }, 100);
    }

    $modal.trigger('open', [trigger]);
  }

  function toggleModal(el, id, focusOnModal) {
    const $modal = $(`#${id}`);

    if ($modal.hasClass('m-modal--open')) {
      closeModal($modal.find('*:first'), el);
    } else {
      openModal(id, focusOnModal, el);
    }
  }

  if ($('.m-modal')[0]) {
    $('.m-modal__close, .m-modal__overlay').click(function () {
      closeModal(this);
    });

    // $('.m-modal button, [href], .m-modal input:not([type="hidden"]), .m-modal select, .m-modal textarea, .m-modal [tabindex]:not([tabindex="-1"]), .m-modal').blur(function(e) {
    //   if ( e.relatedTarget === null
    //     || (
    //       !$(e.relatedTarget).closest('.m-modal')[0]
    //       && !$(e.relatedTarget).is('.m-modal')
    //       && !$(e.relatedTarget).is('.m-modal-trigger__trigger')
    //     )
    //   ) {
    //     closeModal(this);
    //   }
    // });

    $('.m-modal').keydown(function (e) {
      if (e.key === 'Tab') {
        const $focusable = $(this).find(
          'button, [href], input:not([type="hidden"]), select, textarea, [tabindex]:not([tabindex="-1"])'
        );

        const $firstFocusable = $focusable[0];
        const $lastFocusable = $focusable[$focusable.length - 1];

        if (
          ($(e.target).is($lastFocusable) && !e.shiftKey) ||
          ($(e.target).is($firstFocusable) && e.shiftKey)
        ) {
          e.preventDefault();

          if ($(e.target).is($lastFocusable)) {
            $firstFocusable.focus();
          } else {
            $lastFocusable.focus();
          }
        } else if ($(e.target).is('.m-modal')) {
          e.preventDefault();

          $firstFocusable.focus();
        }
      } else if (e.key === 'Escape' || e.key === 'Esc') {
        if (!$(e.target).is('.a-select *')) {
          closeModal(this);
        }
      }
    });

    $('.m-modal').on('open', function () {
      const $modal = $(this);
      const $media = $modal.find('iframe');

      if ($media[0]) {
        $modal.find('.m-video-player').trigger('playMedia');
        console.log('play');
      }
    });

    $('.m-modal').on('close', function () {
      const $modal = $(this);
      const $media = $modal.find('video, audio, iframe');
      const $thumbnail = $modal.find('.m-video__thumbnail');

      if ($media[0]) {
        $modal.find('.m-video-player').trigger('stopMedia');
        console.log('play');
      }
      // $media.attr('src', '');
      $thumbnail.fadeIn();
    });
    $('.m-video-player').on('playMedia', function () {
      $(this).data('player').play();
    });

    $('.m-video-player').on('stopMedia', function () {
      $(this).data('player').stop();
    });
  }

  //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
  // ✅ Molecule: Modal Trigger
  //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

  if ($('.m-modal-trigger__trigger')[0]) {
    $('.m-modal-trigger__trigger').mousedown(function () {
      const modalId = $(this).attr('aria-controls');

      $(this).toggleClass('m-modal-trigger__trigger--active');
      toggleModal(this, modalId, true);
    });

    $('.m-modal-trigger__trigger').keydown(function (e) {
      if (e.key === 'Enter' || e.key === 'spacebar' || e.key === ' ') {
        e.preventDefault();

        const modalId = $(this).attr('aria-controls');

        $(this).addClass('m-modal-trigger__trigger--active');
        openModal(modalId, false, this);
      }
    });
  }
});
