jQuery(document).ready(function ($) {
  if ($('.o-featured-products')[0]) {
    $('.o-featured-products__carousel').slick({
      arrows: true,
      dots: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      infinite: true,
      responsive: [
        {
          breakpoint: 950,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            arrows: true,
            dots: false,
          },
        },
      ],
    });
  }
});
