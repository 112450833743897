jQuery(document).ready(function ($) {
  if ($('.m-search-bar')[0]) {
    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    // ✅ Keyboard
    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

    if ($('.m-search-bar').attr('data-controlledby')) {
      $('.m-search-bar').keydown(function (e) {
        if (e.key === 'Tab') {
          const $focusable = $(this).find(
            'button, [href], input:not([type="hidden"]), select, textarea, [tabindex]:not([tabindex="-1"])'
          );

          const $firstFocusable = $focusable[0];
          const $lastFocusable = $focusable[$focusable.length - 1];

          if (
            ($(e.target).is($lastFocusable) && !e.shiftKey) ||
            ($(e.target).is($firstFocusable) && e.shiftKey)
          ) {
            e.preventDefault();

            if ($(e.target).is($lastFocusable)) {
              $firstFocusable.focus();
            } else {
              $lastFocusable.focus();
            }
          } else if ($(e.target).is('.m-search-bar')) {
            e.preventDefault();

            $firstFocusable.focus();
          }
        } else if (e.key === 'Escape' || e.key === 'Esc') {
          if (!$(e.target).is('.a-select *')) {
            $(this).trigger('unexpand');
          }
        }
      });
    }

    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    // ✅ Close Button
    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

    $('.m-search-bar__close').click(function () {
      $(this).closest('.m-search-bar').trigger('unexpand');
    });

    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    // ✅ Expand
    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

    $('.m-search-bar').on('expand', function () {
      $(this).removeClass('m-search-bar--hidden');
      $(this).find('input').focus();
    });

    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    // ✅ Unexpand
    //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

    $('.m-search-bar').on('unexpand', function () {
      const controllerId = $(this).data('controlledby');

      $(this).addClass('m-search-bar--hidden');
      $(this).find('input').val('');

      if (controllerId) {
        $(`#${controllerId}`).trigger('unexpand').focus();
      }
    });
  }
});
