jQuery(document).ready(function ($) {
  if ($('.o-products-grid')[0]) {
    $('.o-products-grid__products--slider > .m-products__list').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      dots: true,
      responsive: [
        {
          breakpoint: 950,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  }
});
