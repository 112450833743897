jQuery(document).ready(function ($) {
  let $btn = $('.m-tab-list__tab');
  $btn.each(function () {
    if ($(this).text() == 'Features') {
      deactivateTab($btn);
      $(this).click();
      activateTab($(this));
    }
  });

  function activateTab(el) {
    const $item = $(el).closest('.m-tab-list__item');
    const $tab = $item.find('.m-tab-list__tab');

    $item.addClass('m-tab-list__item--selected');

    $tab.attr('aria-selected', 'true').removeAttr('tabindex');
  }

  function deactivateTab(el) {
    const $item = $(el).closest('.m-tab-list__item');
    const $tab = $item.find('.m-tab-list__tab');

    $item.removeClass('m-tab-list__item--selected');

    $tab.attr('aria-selected', 'false').attr('tabindex', -1);
  }

  if ($('.m-tab-list')[0]) {
    $('.m-tab-list__tab').click(function () {
      activateTab(this);
      deactivateTab($('.m-tab-list__tab').not(this));
    });

    $('.m-tab-list__tab').keydown(function (e) {
      const $list = $(this).closest('.m-tab-list__list');
      const isAutomatic = $list.data('automatic');

      switch (e.key) {
        case 'Enter':
        case 'spacebar':
        case ' ':
          if (!isAutomatic) {
            e.preventDefault();
            activateTab(this);
            deactivateTab($('.m-tab-list__tab').not(this));
          }
          break;

        case 'ArrowLeft': {
          e.preventDefault();
          const $prevItem = $(this).closest('.m-tab-list__item').prev();

          if ($prevItem[0]) {
            const $prevTab = $prevItem.find('.m-tab-list__tab');

            $prevTab.focus();

            if (isAutomatic) {
              activateTab($prevTab);
              deactivateTab(this);
            }
          }
          break;
        }
        case 'ArrowRight': {
          e.preventDefault();
          const $nextItem = $(this).closest('.m-tab-list__item').next();

          if ($nextItem[0]) {
            const $nextTab = $nextItem.find('.m-tab-list__tab');

            $nextTab.focus();

            if (isAutomatic) {
              activateTab($nextTab);
              deactivateTab(this);
            }
          }
          break;
        }
        case 'Home': {
          e.preventDefault();
          const $firstTab = $list
            .find('.m-tab-list__item')
            .first()
            .find('.m-tab-list__tab');

          $firstTab.focus();

          if (isAutomatic) {
            activateTab($firstTab);
            deactivateTab(this);
          }
          break;
        }
        case 'End': {
          e.preventDefault();
          const $lastTab = $list
            .find('.m-tab-list__item')
            .last()
            .find('.m-tab-list__tab');

          $lastTab.focus();

          if (isAutomatic) {
            activateTab($lastTab);
            deactivateTab(this);
          }
          break;
        }
      }
    });
  }
});
