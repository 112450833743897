jQuery(document).ready(function ($) {
  if ($('.o-product-resources')[0]) {
    $('.m-resources__list').slick({
      slidesToShow: 3,
      slidesToScroll: 3,
      infinite: true,
      dots: true,
      arrow: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
          },
        },
      ],
    });
  }
});
