// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import Plyr from 'plyr/dist/plyr';
import './components/atoms/hamburger.js'; import './components/atoms/search-button.js'; import './components/atoms/select.js'; import './components/atoms/tab.js'; // eslint-disable-line
import './components/molecules/accordion.js'; import './components/molecules/modal.js'; import './components/molecules/page-nav.js'; import './components/molecules/product-slider.js'; import './components/molecules/search-bar.js'; import './components/molecules/tab-list.js'; import './components/molecules/video-player.js'; import './components/molecules/video.js'; // eslint-disable-line
import './components/organisms/checkout.js'; import './components/organisms/featured-products.js'; import './components/organisms/header.js'; import './components/organisms/hero-carousel.js'; import './components/organisms/notice.js'; import './components/organisms/parts-form.js'; import './components/organisms/product-resources.js'; import './components/organisms/product-tabber.js'; import './components/organisms/products-grid.js'; import './components/organisms/single-product.js'; // eslint-disable-line
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';
/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();
  $('.m-product').matchHeight();

  $(window).on('click', function () {
    $('.' + 'jet-mega-menu-item').removeClass('jet-mega-menu-item--active');
  });

  /**
   * CheckOut Page
   */
  $('#cfw-shipping-method').addClass('cfw-shipping-method');

  $('.cfw-shipping-method, .cfw-payment-method').click(function () {
    if ($('#cfw-shipping-method, #cfw-payment-method').hasClass('active')) {
      $('#cfw-customer-info > .elementor-widget-container').addClass('hide');
    }
  });
  $('.cfw-continue-to-shipping-btn').click(function () {
    $('#cfw-customer-info > .elementor-widget-container').addClass('hide');
  });

  $('.cfw-customer-info').click(function () {
    if ($('#cfw-customer-info').hasClass('active')) {
      $('.elementor-widget-container').removeClass('hide');
    }
  });
  $('.previous-button .cfw-return-to-information-btn').click(function () {
    setTimeout(() => {
      $('.elementor-widget-container').removeClass('hide');
    }, '1050');
  });

  $('.jet-mega-menu-item').each(function () {
    let elem = $(this);
    elem.on('click', function () {
      // e.preventDefault();
      $('.jet-mega-menu-item').removeClass('jet-mega-menu-item--active');
      $(this).toggleClass('jet-mega-menu-item--active');
    });
  });

  $('.megamenu-block__subnav').hide();
  $('.jet-mega-menu-item--active .megamenu-block__subnav').show();
  $('.megamenu-block--item').click(function () {
    // mouse CLICK instead of hover// Only prevent the click on the topmost buttons
    if ($('.megamenu-block__subnav', this).length >= 1) {
      event.preventDefault();
    }
    if ($('.megamenu-block__subnav .mega-menu--subitem', this)) {
      // event.preventDefault();
      event.stopPropagation();
    }
    $('.megamenu-block__subnav').removeClass('show'); // First hide any open menu items
    $(this).find('.megamenu-block__subnav').addClass('show'); // display child
    // event.stopPropagation();
  });
  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  $('.megamenu-block__sub-cat-link').on('click', function () {
    $('.megamenu-block__subnav-items').css('display', 'none');
    $('.megamenu-block__sub-cat-item').removeClass('show');
    $(this).siblings('.megamenu-block__sub-cat-item').addClass('show');
    // event.preventDefault();
  });

  $('.megamenu-block--subitem, .megamenu-block--item__all').on(
    'click',
    function () {
      let senderElement = $(this).attr('href');
      window.location = senderElement;
      return true;
    }
  );

  // $('.megamenu-block__sub-cat-block-item').on('click', function () {
  //   $('.megamenu-block__subnav-items').fadeIn();
  //   $('.megamenu-block__subnav-item').css('display', 'none');
  //   event.preventDefault();
  // });

  // $('.megamenu-block__sub-cat-block-item').on('click', function () {
  //   $('.megamenu-block__subnav-items').css('display', 'none');
  // });
  // $('.megamenu-block__sub-cat-block-item').on('click', function () {
  //   $('.megamenu-block__subnav-items').fadeIn();
  // });

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */

$(window).on('load', function () {
  // jQuery code goes here

  $('.variation dd').after('</br>');

  $('#search-button').on('click', function () {
    if ($('.m-search-bar').hasClass('m-search-bar--hidden')) {
      $('.m-search-bar').slideDown();
      $('.m-search-bar').removeClass('m-search-bar--hidden');
    } else {
      $('.m-search-bar').slideUp();
      $('.m-search-bar').addClass('m-search-bar--hidden');
    }
  });
  $('#search-mobile-button').on('click', function () {
    if ($('.m-search-bar').hasClass('m-search-bar--hidden')) {
      $('.m-search-bar').slideDown();
      $('.m-search-bar').removeClass('m-search-bar--hidden');
    } else {
      $('.m-search-bar').slideUp();
      $('.m-search-bar').addClass('m-search-bar--hidden');
    }
  });
  if ($('.m-video-player')[0]) {
    $('.m-video-player').each(function () {
      const $video = $(this).find('.m-video-player__video');

      const player = new Plyr($video, {
        type: 'video',
        controls: [
          'play',
          'current-time',
          'progress',
          'duration',
          'mute',
          'volume',
          'pip',
          'fullscreen',
        ],
        autoplay: false,
        loadSprite: false,
        fullscreen: {
          enabled: true,
          fallback: true,
          iosNative: true,
          container: null,
        },
        youtube: {
          noCookie: false,
          rel: 0,
          showinfo: 0,
          iv_load_policy: 3,
          modestbranding: 1,
        },
      });

      $(this).data('player', player);
    });
  }

  $('#write-review').on('click', function () {
    $('#review_form_wrapper').slideDown();
    $(this).slideUp();
  });
  $('#header-cta-button').on('click', function () {
    $('#header-cta').slideUp();
  });
  $('.elementor-hero').on('init', function () {
    $('.elementor-hero__slide').removeClass('hide');
  });
  $('.elementor-hero').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    infinite: true,
    // responsive: [
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       dots: false,
    //     },
    //   },
    // ],
  });
  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});
/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
